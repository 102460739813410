<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				直播商品管理
			</h3>
		</div>

        <div class="box">
            <div class="tiaojian">
                <div class="left">
                    <strong>商品名称: </strong
                    ><el-input
                        v-model="form.goods_name"
                        placeholder="请输入商品名称以筛选"
                    ></el-input>
                </div>
                <span class="shaixuan shou" @click="search">筛选</span>
            </div>
            <el-table border :data="list" style="width: 100%">
                <el-table-column prop="id" label="商品ID"></el-table-column>
                <el-table-column prop="name" label="商品名称"></el-table-column>
                <el-table-column prop="price" label="市场价"></el-table-column>
                <el-table-column prop="status" label="上架状态">
                    <div class="fyslot" slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0" size="mini" type="info"
                        >下架</el-tag>
                        <el-tag v-if="scope.row.status == 1" size="mini" type="info"
                        >上架</el-tag>
                    </div>
                </el-table-column>
                <el-table-column label="商品缩略图" width="120">
                    <div class="listimg" slot-scope="scope">
                        <img :src="imgurl + scope.row.cover_pic" alt="" />
                    </div>
                </el-table-column>
                <el-table-column prop="goods_num" label="商品库存"></el-table-column>
                <el-table-column label="直播库存">
                    <template slot-scope="scope">
                        <span style="margin-right: 20px">{{ scope.row.live_num }}</span>
                        <el-button size="mini" type="primary" plain @click="editLiveNum(scope.row.id)">修改</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="直播价格">
                    <template slot-scope="scope">
                        <span style="margin-right: 20px">{{ scope.row.live_price }}</span>
                        <el-button size="mini" type="primary" plain @click="editLivePrice(scope.row.id)">修改</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="购买数量">
                    <template slot-scope="scope">
                        <span style="margin-right: 20px">{{ scope.row.live_full_num }}</span>
                        <el-button size="mini" type="primary" plain @click="editLivefullNum(scope.row.id)">修改</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="实发数量">
                    <template slot-scope="scope">
                        <span style="margin-right: 20px">{{ scope.row.send_num }}</span>
                        <el-button size="mini" type="primary" plain @click="editLiveSendNum(scope.row.id)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="CurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
        
        <el-dialog title="修改直播商品库存" :visible.sync="dialogFormVisible" width="25%">
            <el-form :model="formData">
                <el-form-item label="直播商品库存" :label-width="formLabelWidth">
                    <el-input v-model="formData.live_num" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditLiveNum">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改直播商品价格" :visible.sync="dialogVisiblePrice" width="25%">
            <el-form :model="formData">
                <el-form-item label="直播商品价格" :label-width="formLabelWidth">
                    <el-input v-model="formData.live_price" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisiblePrice = false">取 消</el-button>
                <el-button type="primary" @click="sureEditLivePrice">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改购买数量" :visible.sync="dialogVisibleFullnum" width="25%">
            <el-form :model="formData">
                <el-form-item label="购买商品数量" :label-width="formLabelWidth">
                    <el-input v-model="formData.live_full_num" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleFullnum = false">取 消</el-button>
                <el-button type="primary" @click="sureEditLiveFullnum">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改赠送数量" :visible.sync="dialogVisibleSendnum" width="25%">
            <el-form :model="formData">
                <el-form-item label="赠送赠送数量" :label-width="formLabelWidth">
                    <el-input v-model="formData.send_num" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleSendnum = false">取 消</el-button>
                <el-button type="primary" @click="sureEditLiveSendnum">确 定</el-button>
            </div>
        </el-dialog>

    </el-card>
</template>
<script>
import { getLiveGoodsList,liveGoodsEdit } from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
    name: "livegoods",
    components: {},
    data(){
        return{
            list: [],
            form:{
                live_id:'',
                limit:10,
                page:1,
                goods_name:''
            },
            total: 0,
            imgurl: "",
            dialogFormVisible: false,
            dialogVisiblePrice:false,
            dialogVisibleFullnum:false,
            dialogVisibleSendnum:false,
            formLabelWidth: '100px',
            formData:{
                goods_id:'',
                live_num:'', // 商品库存
                live_price:'', //商品价格
                live_full_num:'', //购买数量
                send_num:'', // 赠送数量
                live_id: '',
                type: ''
            }
        }
    },
    methods:{
        goBack() {
            console.log('go back');
            this.$router.back(-1)
        },

        CurrentChange(e) {
            this.form.page = e
            this.getlist()
        },
        search() {
            this.form.page = 1
            this.getlist()
        },
        async getlist() {
            this.form.live_id = this.$route.query.liveid;
            this.formData.live_id = this.$route.query.liveid;
            const res = await getLiveGoodsList(this.form);
            this.list = res.data.data.data;
            this.total = res.data.data.total;
        },
        editLiveNum(id){
            this.dialogFormVisible = true;
            console.log(id)
            this.formData.goods_id = id;
        },
        // 修改库存
        async sureEditLiveNum(){
            this.formData.type = 1
            const { data } = await liveGoodsEdit(this.formData);
            if (data.code != 200) return this.$message.error(data.data);
            this.$message.success(data.data);
            this.dialogFormVisible = false;
            this.getlist();
        },

        editLivePrice(id){
            this.dialogVisiblePrice = true;
            console.log(id)
            this.formData.goods_id = id;
        },
        // 修改价格
        async sureEditLivePrice(){
            this.formData.type = 2
            const { data } = await liveGoodsEdit(this.formData);
            if (data.code != 200) return this.$message.error(data.data);
            this.$message.success(data.data);
            this.dialogVisiblePrice = false;
            this.getlist();
        },

        editLivefullNum(id){
            this.dialogVisibleFullnum = true;
            this.formData.goods_id = id;
        },
        // 修改购买数量
        async sureEditLiveFullnum(){
            this.formData.type = 3
            const { data } = await liveGoodsEdit(this.formData);
            if (data.code != 200) return this.$message.error(data.data);
            this.$message.success(data.data);
            this.dialogVisibleFullnum = false;
            this.getlist();
        },

        editLiveSendNum(id){
            this.dialogVisibleSendnum = true;
            this.formData.goods_id = id;
        },
        // 修改赠送数量
        async sureEditLiveSendnum(){
            this.formData.type = 4
            const { data } = await liveGoodsEdit(this.formData);
            if (data.code != 200) return this.$message.error(data.data);
            this.$message.success(data.data);
            this.dialogVisibleSendnum = false;
            this.getlist();
        },
        

    },
    //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist();
    console.log(this.$route.query.liveid)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    getSystemsettings.then((res) => {
        console.log(res)
        this.imgurl = res;
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.listimg {
    width: 50px;
    img {
      width: 100%;
    }
  }
  .tiaojian {
    margin: 20px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #edeff0;
    position: relative;
    .left {
        strong {
        font-weight: normal;
        font-size: 14px;
        color: #606266;
        padding-top: 10px;
        margin-right: 2px;
        }
        .el-input {
        width: 300px;
        margin-right: 20px;
        /deep/.el-input__inner {
            height: 30px;
        }
        }
        .checkboxBox {
        padding-top: 10px;
        margin: 0 0 20px 0;
        }
    }

    .shaixuan {
        background-color: #18bdff;
        color: aliceblue;
        padding: 3px 10px;
        border-radius: 2px;
        position: absolute;
        bottom: 15px;
        left: 400px;
        &:hover {
        background-color: #1abe9c;
        }
    }
}
</style>